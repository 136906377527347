<template>
    <tbody>
        <tr
            v-for="(item, index) in items"
            :key="index"
            :class="`${isStatusClosed(item) ? 'disabled' : ''}`"
        >
            <!-- === === DEVICES === === -->
            <td class="truncate">
                <DoubleLine
                    :firtstLine="`${$get(
                        item,
                        'model.manufacturer',
                        ''
                    )} ${$get(item, 'model.model', '')}`"
                    :secondLine="`${$get(item, 'model.deviceType', '')}`"
                />
            </td>
            <!-- === === SN === === -->
            <td class="truncate">
                <DoubleLine
                    :firtstLine="`${$get(item, 'sn', '')}`"
                    :secondLine="`${$get(item, 'identificationNumber', '')}`"
                />
            </td>

            <!-- === === SERVICE BASSE === === -->
            <td class="truncate">
                {{ $get(item, 'serviceBase.field', '') }}
            </td>
            <!-- === === CLIENT === === -->
            <td class="truncate">
                <DoubleLine
                    :firtstLine="`${$get(item, 'client.name', '')}`"
                    :secondLine="`${branch(
                        item.branch,
                        $get(item, 'client.branches', '')
                    )}`"
                />
            </td>
            <!-- === === SERVICE PRICE === === -->
            <td class="truncate">
                {{ $get(item, 'model.servicePrice.name', '') }}
            </td>
            <!-- === === STATUS === === -->
            <td>
                <v-chip
                    :color="$get(item, 'status.color.background', '#555555')"
                    :style="`color: ${$get(
                        item,
                        'status.color.font',
                        '#FFFFFF'
                    )}`"
                    dark
                    label
                    small
                    >{{ $get(item, 'status.name', $t('users:empty')) }}</v-chip
                >
            </td>
            <td>
                <v-tooltip
                    v-if="
                        (item.guaranteeTo &&
                            $moment(item.guaranteeTo).isSameOrAfter(
                                new Date(),
                                'days'
                            )) ||
                        (item.partGuarantee &&
                            $moment(item.partGuarantee).isSameOrAfter(
                                new Date(),
                                'days'
                            ))
                    "
                    bottom
                >
                    <template v-slot:activator="{ on }">
                        <v-avatar
                            class="cursor-pointer"
                            v-on="on"
                            color="rgb(150, 75, 132)"
                            size="25"
                        >
                            <span class="white--text">G</span></v-avatar
                        >
                    </template>
                    <span>{{ $t('devices:isWarranty') }}</span>
                </v-tooltip>
            </td>
            <td class="d-flex align-center justify-end">
                <v-icon small v-if="hasFile(item.folders)"
                    >mdi-paperclip</v-icon
                >
                <v-menu bottom v-if="remove()">
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn icon small v-bind="attrs" v-on="on">
                            <v-icon color="iconGray" small
                                >mdi-dots-vertical</v-icon
                            >
                        </v-btn>
                    </template>
                    <v-list dense>
                        <!-- === === REMOVE === === -->
                        <v-list-item @click="openConfirmModal(item._id)">
                            <v-list-item-icon class="mr-0">
                                <v-icon color="red" small>mdi-delete</v-icon>
                            </v-list-item-icon>
                            <v-list-item-content>
                                <v-list-item-title>Usuń</v-list-item-title>
                            </v-list-item-content>
                        </v-list-item>
                    </v-list>
                </v-menu>
                <v-btn small router :to="`/devices/${item._id}`" icon>
                    <v-icon color="secondary" small
                        >mdi-arrow-right-thick</v-icon
                    >
                </v-btn>
            </td>
        </tr>
        <ConfirmModal
            :openConfirm="openConfirm"
            @close-confirm="closeConfirmModal"
        >
            <div slot="main"><ConfirmContent /></div>
            <div slot="buttons">
                <div class="d-flex justify-center">
                    <v-btn
                        color="rgb(117, 117, 117)"
                        width="110"
                        height="40"
                        @click="closeConfirmModal()"
                        class="white--text mx-2"
                        >{{ $t('global:cancel') }}</v-btn
                    >
                    <v-btn
                        width="110"
                        height="40"
                        color="#445d75"
                        class="white--text mx-2"
                        @click="deleteItem()"
                        >{{ $t('global:remove') }}</v-btn
                    >
                </div>
            </div>
        </ConfirmModal>
    </tbody>
</template>
<script>
import { mapActions, mapGetters } from 'vuex'
import ConfirmContent from '../../Departments/ConfirmModal/ConfirmContent.vue'
export default {
    props: ['items'],
    data: () => ({
        openConfirm: false,
        removeItemID: '',
    }),
    components: {
        ConfirmContent,
    },
    computed: {
        ...mapGetters(['getProfileDetails']),
    },
    methods: {
        ...mapActions(['deleteDevice']),
        openConfirmModal(id) {
            this.removeItemID = id
            this.openConfirm = true
        },
        closeConfirmModal() {
            this.openConfirm = false
        },
        branch(branch, branches) {
            if (branches) {
                let res = branches.find(el => el._id === branch)
                if (res) {
                    if (res.address.at(-1) == '/') {
                        return res.address.replace('/', '')
                    }
                    return `${res.postCode} ${res.city} ${res.address.replace(
                        '/undefined',
                        ''
                    )}`
                }
            }
        },
        async deleteItem() {
            let result = await this.deleteDevice({ id: this.removeItemID })
            if (result) this.openConfirm = false
        },
        remove() {
            return this.checkPermissions('DELETE')
        },
    },
}
</script>
<style scoped>
.grey {
    color: grey;
}
</style>
