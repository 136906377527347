<template>
    <v-container class="p-relative">
        <v-row>
            <v-col cols="12" md="12" class="py-0">
                <RegistryHeader
                    :title="getDevicesRegistry.name"
                    :avatarData="getDevicesRegistry.currentMenager"
                    @clearSearch="clearTable"
                />
                <Table
                    :title="getDevicesRegistry.name"
                    :defaultFields="getDevicesSearchFields.searchFields"
                    :items="getDevicesTable.items"
                    @search-fields="setSearchFields"
                    :headers="headers"
                    :length="getDevicesTable.pages"
                    :page="getDevicesTable.page"
                    @search="setSearch"
                    @clear-table="clearTable"
                    @change-page="setPage"
                    @sort="setSort"
                    :loading="getDevicesTable.loading"
                >
                    <Columns slot="columns" :items="getDevicesTable.items" />
                </Table>

                <Modal
                    :title="$t('devices:additionDevice')"
                    :open="open"
                    :height="
                        window.width >= 1200 ? '760' : 'calc( 600px - 110px )'
                    "
                    :width="`${window.width - 200}`"
                    v-on:close="closeModal"
                >
                    <AEContent slot="AEContent" />
                    <Buttons
                        :action="'create'"
                        slot="buttons"
                        @closeModal="open = false"
                    />
                </Modal>
                <div class="d-flex justify-end buttons--action">
                    <v-tooltip left>
                        <template v-slot:activator="{ on }">
                            <v-btn
                                fab
                                dark
                                v-on="on"
                                small
                                v-if="add()"
                                @click="addDevice()"
                                class="buttons--add margin--1"
                            >
                                <v-icon small>mdi-plus</v-icon>
                            </v-btn>
                        </template>
                        <span>{{ $t('devices:addDevices') }}</span>
                    </v-tooltip>
                </div></v-col
            >
        </v-row>
    </v-container>
</template>
<script>
import { mapGetters, mapActions, mapMutations } from 'vuex'
import store from './../../../store/index'
import Columns from './../../../components/Registries/Devices/Table/Columns'
import AEContent from './../../../components/Registries/Devices/Modal/Content'
import Buttons from './../../../components/Registries/Devices/Modal/Buttons'
export default {
    data() {
        return {
            open: false,
            window: {
                width: 0,
            },
            headers: [
                {
                    text: this.$t('devices:device'),
                    value: 'model.manufacturer join model.model',
                    width: '20%',
                    showSearch: true,
                },
                {
                    text: this.$t('devices:sn') + ' | ID',
                    value: 'sn',
                    width: '15%',
                    showSearch: true,
                },
                {
                    text: this.$t('devices:serviceBase'),
                    value: 'serviceBase.field',
                    width: '20%',
                    showSearch: true,
                },
                {
                    text: this.$t('devices:client'),
                    value: 'client.name',
                    width: '25%',
                    showSearch: true,
                },
                {
                    text: this.$t('devices:servicePrice'),
                    value: 'model.servicePrice.name',
                    width: '15%',
                    align: 'left',
                    showSearch: true,
                },
                {
                    text: this.$t('devices:status'),
                    value: 'status.name',
                    width: '7%',
                    showSearch: true,
                },
                {
                    text: '',
                    value: 'warranty',
                    width: '4%',
                },
                {
                    text: '',
                    value: 'arrow',
                    align: 'left',
                    width: '100px',
                    sortable: false,
                },
            ],
        }
    },
    components: {
        Columns,
        AEContent,
        Buttons,
    },
    computed: {
        ...mapGetters([
            'getDevicesTable',
            'getDevicesSearchFields',
            'getDevicesRegistry',
            'getSearch',
        ]),
    },
    methods: {
        ...mapActions(['fetchUsers', 'fetchDevicesTable']),
        ...mapMutations([
            'clearDevicesModal',
            'clearDeviceErrors',
            'setDevicesTable',
            'setDevicesSearch',
            'clearDevicesTable',
        ]),
        closeModal() {
            // this.clearDevicesErrors()
            this.open = false
        },
        add() {
            return this.checkPermissions('CREATE')
        },
        async addDevice() {
            await void this.fetchUsers({
                select: 'name lastname mail status',
                where: {
                    isUser: true,
                    'status.state': 'ACTIVE',
                    isDeleted: false,
                },
            })
            this.open = true
            this.clearDeviceErrors()
            this.clearDevicesModal()
        },
        setSearchFields(searchFields) {
            // console.log(searchFields)
            if (searchFields.includes('identificationNumber')) {
            }
            this.setDevicesSearch(searchFields)
        },
        setSearch(search) {
            this.setDevicesTable({ search, page: 1 })
            this.fetchDevicesTable()
        },
        setPage(page) {
            this.setDevicesTable({ page })
            this.fetchDevicesTable()
        },
        handleResize() {
            this.window.width = window.innerWidth
        },
        setSort(sort) {
            this.setDevicesTable({ sort })
            this.fetchDevicesTable()
        },
        clearTable() {
            this.getSearch.text = ''
            this.clearDevicesTable()
            this.fetchDevicesTable()
        },
    },
    created() {
        window.addEventListener('resize', this.handleResize)
        this.handleResize()
    },
    beforeRouteEnter(to, from, next) {
        if (from.matched[1] && from.matched[1].path !== '/devices/:id')
            store.commit('clearDevicesTable')
        store.dispatch('fetchDevicesTable').then(() => next())
    },
}
</script>
